import React from 'react';
import { useLocation } from '@reach/router';
import { navigate } from 'gatsby';

import { Modal } from '../../shared/Modal';
import { ModalTitle } from '../../shared/Modal/ModalTitle';
import Check from '../../../images/svg/check.svg';
import { Button } from '../../shared/Button';

export const PasswordChangedModal = () => {
  const { state } = useLocation();

  const { passwordChanged } = state || {};

  const onClose = () => navigate('/ucet/prihlaseni');

  if (passwordChanged) {
    return (
      <Modal onClose={onClose} data-testid="password-changed-modal">
        <ModalTitle>Vaše heslo bylo nastaveno</ModalTitle>
        <Check className="inline-block w-32 h-32 fill-green" />
        <div className="text-lg">Vaše heslo bylo úspěšně nastaveno.</div>
        <Button
          type="button"
          onClick={onClose}
          border="normal"
          className="w-64"
          data-testid="button-close-modal"
        >
          OK
        </Button>
      </Modal>
    );
  }

  return null;
};

import React, { useRef } from 'react';
import ReactDOM from 'react-dom';
import { useOnClickOutside } from '../../../hooks/useOnClickOutside';
import close from '../../../images/times.svg';

const ModalContent = ({ children, onClose, ...rest }) => {
  const content = useRef();
  useOnClickOutside(content, onClose);

  return (
    <div
      className="bg-black/75 fixed w-full h-full top-0 left-0 flex items-center justify-center z-40"
      {...rest}
    >
      <div
        ref={content}
        className="relative bg-white rounded-md p-4 md:px-8 md:py-12 text-center max-w-2xl z-50 space-y-8"
      >
        <img
          src={close}
          alt="Zavřít"
          onClick={onClose}
          className="absolute top-0 right-0 mt-4 mr-4 hover:cursor-pointer"
          data-testid="modal-close-button"
        />
        {children}
      </div>
    </div>
  );
};

export const Modal = (props) =>
  ReactDOM.createPortal(
    <ModalContent {...props} />,
    document.getElementById('___gatsby')
  );
